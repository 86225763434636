export const TradeStatus = {
    /**
     * 创建
     */
    CREATED: 0,

    /**
     * 支付成功
     */
    PAIED_SUCCESS: 1,

    /**
     * 申请退款
     */
    REFUND_APPLIED: 2,

    /**
     * 退款中
     */
    REFUND_PROCESSING: 3,

    /**
     * 退款异常
     */
    REFUND_ABNORMAL: 4,

    /**
     * 退款成功
     */
    REFUND_SUCCESS: 5,

    /**
     * 退款关闭
     */
    REFUND_CLOSED: 6,

    /**
     * 交易关闭
     */
    TRADE_CLOSED: 7,
}

export const PaymentType = {
    /**
     * 免费
     */
    FREE: 0,

    /**
     * 微信支付
     */
    WEIXIN: 1,
}

function doFormatTradeStatus(trade) {
    let statusStr = null;
    switch (trade.status) {
        case TradeStatus.CREATED:
            statusStr = "未付款";
            break;
        case TradeStatus.PAIED_SUCCESS:
            if (trade.delivered) {
                statusStr = "已发货";
            } else {
                statusStr = "已付款";
            }
            break;
        case TradeStatus.REFUND_APPLIED:
            statusStr = "申请退款";
            break;
        case TradeStatus.REFUND_PROCESSING:
            statusStr = "退款中";
            break;
        case TradeStatus.REFUND_CLOSED:
            statusStr = "退款关闭";
            break;
        case TradeStatus.REFUND_SUCCESS:
            statusStr = "退款成功";
            break;
        case TradeStatus.REFUND_ABNORMAL:
            statusStr = "退款异常";
            break;
        case TradeStatus.TRADE_CLOSED:
            statusStr = "交易关闭";
            break;
    }
    return statusStr;
}

function doFormatRefundStatus(refund) {
    let statusStr = null;
    switch (refund.status) {
        case TradeStatus.REFUND_PROCESSING:
            statusStr = "退款中";
            break;
        case TradeStatus.REFUND_SUCCESS:
            statusStr = "退款成功";
            break;
        case TradeStatus.REFUND_CLOSED:
            statusStr = "退款关闭";
            break;
        case TradeStatus.REFUND_ABNORMAL:
            statusStr = "退款异常";
            break;
    }
    return statusStr;
}

const TradeUtils = {
    TradeStatus,
    PaymentType,
    formatTradeStatus: doFormatTradeStatus,
    formatRefundStatus: doFormatRefundStatus,
}

export default TradeUtils;