<template>
  <div class="padding-left-large layout-vertical" style="height: 100vh;" >
    <el-menu :default-active="1" mode="horizontal" class="menu layout-inflexible" @select="handleMenuSelect">
      <el-menu-item :index="1">订单管理</el-menu-item>
      <el-menu-item :index="2">
        <span>退款申请</span>
        <el-badge :hidden="refundApplicationCount === 0" :value="refundApplicationCount" :max="10"
                  style="margin-top: -4px;"></el-badge>
      </el-menu-item>
    </el-menu>
    <div class="layout-flexible layout-horizontal" style="overflow: hidden;">
      <div class="layout-flexible layout-vertical" style="max-width: 880px;">
        <div class="layout-inflexible margin-top layout-horizontal padding-horizontal-large">
          <span style="width: 20%;">订单</span>
          <span style="width: 10%;" class="margin-left-large">创建时间</span>
          <span style="width: 10%;" class="margin-left">支付者</span>
          <span style="width: 35%;" class="margin-left">订单说明</span>
          <span style="width: 10%;" class="margin-left">订单状态</span>
          <span style="width: 15%;" class="margin-left layout-horizontal layout-right">金额</span>
        </div>
        <div class="layout-flexible" style="overflow: hidden;">
          <scheme-trade-list-panel
              v-if="menuIndex === MENU_INDEX_TRADE_LIST"
              ref="listPanel"
              :organization-id="organizationId"
              style="height: 100%;"
              class="layout-flexible"
              @itemclick="handleSchemeTradeItemClick"></scheme-trade-list-panel>
          <refund-application-list-panel
              v-else-if="menuIndex === MENU_INDEX_REFUND_APPLICATION_LIST"
              ref="listPanel"
              :organization-id="organizationId"
              style="height: 100%;"
              @itemclick="handleSchemeTradeItemClick"
          ></refund-application-list-panel>
        </div>
      </div>
      <div class="layout-inflexible" style="overflow: hidden; height: 100%;">
        <ys-infinite-scroll-view style="height: 100%;">
          <transition name="el-fade-in">
            <scheme-trade-panel v-if="schemeTrade" :scheme-trade.sync="schemeTrade"
                                @update:schemeTrade="handleSchemeTradeChange"></scheme-trade-panel>
          </transition>
        </ys-infinite-scroll-view>
      </div>
    </div>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import SchemeTradeListPanel from "@/pages/trade/basic/SchemeTradeListPanel";
import SchemeTradePanel from "@/pages/trade/basic/SchemeTradePanel";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import RefundApplicationListPanel from "@/pages/trade/basic/RefundApplicationListPanel";

const MENU_INDEX_TRADE_LIST = 1;

const MENU_INDEX_REFUND_APPLICATION_LIST = 2;


function doLoadSchemeTrade(tradeNo) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/scheme/trade/get/by_trade_no',
    data: {
      tradeNo: tradeNo,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    if (res.data.organization.id === this.organizationId) {
      if (this.schemeTrade?.id === res.data.id) {
        this.schemeTrade = res.data;
      }
      this.handleSchemeTradeChange(res.data);
    }
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadRefundApplicationCount(organizationId) {
  this.$reqGet({
    path: '/scheme/trade/refund_application/count',
    data: {
      organizationId,
    }
  }).then(res => {
    if (this.organizationId === organizationId) {
      this.refundApplicationCount = res.data;
    }
  })
}

export default {
  name: "SchemeTradeList",
  components: {RefundApplicationListPanel, YsInfiniteScrollView, SchemeTradePanel, SchemeTradeListPanel},
  mixins: [httpapi],
  props: {
    organizationId: Number
  },
  data() {
    return {
      MENU_INDEX_TRADE_LIST,
      MENU_INDEX_REFUND_APPLICATION_LIST,

      schemeTrade: null,

      loadingCode: 1,
      busyLoadingCode: 0,

      refundApplicationCount: 0,

      menuIndex: MENU_INDEX_TRADE_LIST,
    }
  },
  mounted: function () {
    window.eventBus.$on('notify:trade', this.handleSchemeTradeNotify);
  },
  destroyed: function () {
    window.eventBus.$off('notify:trade', this.handleSchemeTradeNotify);
  },
  watch: {
    organizationId: {
      handler: function () {
        this.loadingCode++;
        this.loadRefundApplicationCount();
      },
      immediate: true,
    }
  },
  methods: {
    handleMenuSelect(e) {
      this.menuIndex = e;
    },
    handleSchemeTradeItemClick: function (e) {
      this.schemeTrade = e.item;
    },
    handleSchemeTradeChange: function (schemeTrade) {
      this.$refs.listPanel.updateSchemeTrade(schemeTrade);
    },
    handleSchemeTradeNotify(event) {
      let tradeNo = event.tradeNo;
      doLoadSchemeTrade.bind(this)(tradeNo);
      this.loadRefundApplicationCount();
    },
    loadRefundApplicationCount() {
      if (this.organizationId) {
        doLoadRefundApplicationCount.bind(this)(this.organizationId);
      }
    }
  }
}
</script>

<style scoped>

.menu {
  width: 50vw;
}

.el-menu--horizontal {
  background-color: transparent;
}

.el-menu--horizontal .el-menu-item.is-active {
  color: #409EFF;
  font-weight: bold !important;
}

</style>