<template>
  <ys-dialog :visible.sync="isVisible" @update:visible="visible=>$emit('update:visible', visible)" title="关闭交易">
    <div class="padding padding-large font-size-medium">
      <div>
        <span>订单号：</span>
        <span>{{ schemeTrade.trade.tradeNo }}</span>
      </div>
      <div>
        <span>创建时间：</span>
        <span>{{ TimeUtils.format(timeFormat, schemeTrade.trade.createTime) }}</span>
      </div>
      <div>
        <span>状态：</span>
        <span>{{ TradeUtils.formatTradeStatus(schemeTrade.trade) }}</span>
      </div>
      <div>
        <span>金额：</span>
        <span class="font-weight-bold font-size-extra-large font-color-danger">￥{{amountStr}}</span>
      </div>
      <ys-textarea class="margin-top" placeholder="请输入关闭原因" :max-length="80" v-model="reasonInput"></ys-textarea>
      <div class="margin-top font-color-secondary ">
        关闭交易后，会将订单金额全额退还给用户（已部分退款的用户，将退回剩余的款项金额）；并停止相应的干预服务。如果您希望仅退款但是不停止已开始的干预方案，可选择退款操作。
      </div>
    </div>
    <div slot="footer">
      <ys-button type="secondary" lighting @click="handleCancelClick">取消</ys-button>
      <ys-button class="margin-left" lighting @click="handleConfirmClick">关闭交易</ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import YsTextarea from "@/components/wedigets/YsTextarea";
import {TimeUtils} from "@/assets/javascript/time-utils";
import TradeUtils from "@/assets/javascript/trade-utils";
import YsButton from "@/components/wedigets/YsButton";
import ExactNumber from "@/assets/javascript/exact-number";
import httpapi from "@/assets/javascript/httpapi";
import TextUtils from "@/assets/javascript/text-utils";

function doCloseSchemeTrade() {
  if(this.loadingCode === this.busyLoadingCode) {
    this.$message.error('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/scheme/trade/close',
    data: {
      id: this.schemeTrade.id,
      reason: this.reasonInput,
    }
  }).then(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.success('交易已关闭');
    this.isVisible = true;
    this.$emit('update:visible', false);
    this.$emit('confirm');
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "CloseTradeDialog",
  mixins: [httpapi],
  components: {YsButton, YsTextarea, YsDialog},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    schemeTrade: Object,
  },
  data() {
    return {
      /**
       * 常量
       */
      TimeUtils,
      TradeUtils,
      timeFormat: 'yyyy-MM-dd HH:mm:ss',

      /**
       * 视图
       */
      amountStr: null,
      reasonInput: null,

      /**
       * 状态
       */
      isVisible: false,
      loadingCode: 1,
      busyLoadingCode: 0,

    }
  },
  watch: {
    schemeTrade: {
      handler: function (schemeTrade) {
        this.loadingCode ++;
        this.amountStr = schemeTrade?.trade?.amount ? ExactNumber.stringify(schemeTrade.trade.amount) : "0.00";
      },
      immediate: true,
    },
    visible: {
      handler: function (visible) {
        this.reasonInput = null;
        this.isVisible = visible;

      },
      immediate: true,
    }
  },
  methods: {
    handleCancelClick: function () {
      this.isVisible = false;
      this.$emit('update:visible', false);
    },
    handleConfirmClick: function () {
      if(TextUtils.isBlank(this.reasonInput)) {
        this.$message.warning('请输入关闭原因');
        return;
      }
      doCloseSchemeTrade.bind(this)();
    }
  }

}
</script>

<style scoped>

</style>