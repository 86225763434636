<template>
  <ys-dialog :visible.sync="isVisible" @update:visible="visible=>$emit('update:visible', visible)" title="退款">
    <div class="padding padding-large font-size-medium">
      <div>
        <span>订单号：</span>
        <span>{{ schemeTrade.trade.tradeNo }}</span>
      </div>
      <div>
        <span>创建时间：</span>
        <span>{{ TimeUtils.format(timeFormat, schemeTrade.trade.createTime) }}</span>
      </div>
      <div>
        <span>状态：</span>
        <span>{{ TradeUtils.formatTradeStatus(schemeTrade.trade) }}</span>
      </div>
      <div>
        <span>订单金额：</span>
        <span class="font-weight-bold font-size-extra-large font-color-danger">￥{{ amountStr }}</span>
      </div>
      <div>
        <span>可退金额：</span>
        <span class="font-weight-bold font-size-extra-large font-color-danger">￥{{ remainAmountStr }}</span>
      </div>
      <div>
        <span>退款￥：</span>
        <ys-number placeholder="请输入退款金额" v-model="refundAmount" :digits="2" :max="remainAmount" :min="ExactNumber.of(0)"></ys-number>
        <span v-if="refundAmount && ExactNumber.compare(remainAmount, refundAmount) === 0" class="font-color-danger margin-left">全额退款</span>
      </div>
      <div class="margin-top">
        <ys-textarea placeholder="退款原因" :maxlength="80" v-model="reasonInput"></ys-textarea>
      </div>
      <div class="font-color-secondary margin-top">
        退款金额不得超过该订单：实际支付的金额-已退款的金额；退款金额不能为￥0.00；退款操作不会停止已开始的干预方案，若您需要全额退款且停止该用户的干预方案，请选择关闭交易操作。
      </div>
    </div>
    <div slot="footer">
      <ys-button type="secondary" lighting @click="handleCancelClick">取消</ys-button>
      <ys-button class="margin-left" lighting @click="handleConfirmClick">
        <span>退款</span>
        <span v-if="refundAmount && ExactNumber.compare(refundAmount, ExactNumber.of(0)) > 0">
          : ￥{{ ExactNumber.stringify(refundAmount) }}
        </span>
      </ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import ExactNumber from "@/assets/javascript/exact-number";
import YsButton from "@/components/wedigets/YsButton";
import {TimeUtils} from "@/assets/javascript/time-utils";
import TradeUtils, {TradeStatus} from "@/assets/javascript/trade-utils";
import YsNumber from "@/components/wedigets/YsNumber";
import YsTextarea from "@/components/wedigets/YsTextarea";
import httpapi from "@/assets/javascript/httpapi";
import TextUtils from "@/assets/javascript/text-utils";

function doRefund() {
  if(this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/scheme/trade/refund',
    data: {
      id: this.schemeTrade.id,
      amount: this.refundAmount,
      reason: this.reasonInput,
    }
  }).then(res=>{
    if(loadingCode !== this.loadingCode) return;
    if(res.data) {
      doRefreshRefundStatus.bind(this)();
    } else {
      this.$message.success('已退款');
      this.hide();
      this.$emit('confirm');
    }
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.success('已退款');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doRefreshRefundStatus(refundNo) {
  let loadingCode = this.loadingCode;
  this.$reqPost({
    path: '/scheme/trade/refund_status/refresh',
    data: {
      refundNo,
    }
  }).complete(()=>{
    if(loadingCode != this.loadingCode) return;
    this.$message.success('已退款');
    this.hide();
    this.$emit('confirm');
  })
}

export default {
  name: "RefundDialog",
  mixins: [httpapi],
  components: {YsTextarea, YsNumber, YsButton, YsDialog},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    schemeTrade: Object,
  },
  data() {
    return {
      TimeUtils,
      TradeUtils,
      ExactNumber,
      timeFormat: 'yyyy-MM-dd HH:mm:ss',

      isVisible: false,

      amountStr: null,
      remainAmount: null,
      remainAmountStr: null,

      refundAmount: null,
      reasonInput: null,

      loadingCode: 1,
      busyLoadingCode: 0,
    }
  },
  watch: {
    visible: {
      handler: function () {
        this.isVisible = this.visible;
        this.reasonInput = this.schemeTrade?.trade?.status === TradeStatus.REFUND_APPLIED ? "同意用户申请退款" : "协商退款";
      },
      immediate: true,
    },
    schemeTrade: {
      handler: function () {
        this.loadingCode++;
        this.amountStr = this.schemeTrade?.trade?.amount ? ExactNumber.stringify(this.schemeTrade.trade.amount) : "0.00";
        let remainAmount;
        if (this.schemeTrade?.trade?.refundAmount) {
          remainAmount = ExactNumber.of(this.schemeTrade.trade.amount.value - this.schemeTrade.trade.refundAmount.value, 2);
        } else {
          remainAmount = ExactNumber.of(this.schemeTrade.trade.amount.value, 2);
        }
        this.remainAmount = remainAmount;
        this.remainAmountStr = ExactNumber.stringify(remainAmount);
        this.refundAmount = ExactNumber.of(remainAmount.value, remainAmount.digits);
      },
      immediate: true,
    }
  },
  methods: {
    hide: function () {
      this.isVisible = false;
      this.$emit('update:visible', false);
    },
    handleCancelClick: function () {
      this.hide();
    },
    handleConfirmClick() {
      if(!this.refundAmount || ExactNumber.compare(this.refundAmount, ExactNumber.of(0)) <= 0) {
        this.$message.warning('请输入有效退款金额');
        return;
      }
      if(TextUtils.isBlank(this.reasonInput)) {
        this.$message.warning('请输入退款原因');
        return;
      }
      doRefund.bind(this)();
    }
  }
}
</script>

<style scoped>

</style>