<template>
  <ys-dialog :visible.sync="isVisible" @update:visible="visible=>$emit('update:visible', visible)" title="开始干预">
    <div class="padding padding-large font-size-medium">
      <div>
        <span>订单号：</span>
        <span>{{ schemeTrade.trade.tradeNo }}</span>
      </div>
      <div>
        <span>创建时间：</span>
        <span>{{ TimeUtils.format(timeFormat, schemeTrade.trade.createTime) }}</span>
      </div>
      <div>
        <span>状态：</span>
        <span>{{ TradeUtils.formatTradeStatus(schemeTrade.trade) }}</span>
      </div>
      <div>
        <span>金额：</span>
        <span class="font-weight-bold font-size-extra-large font-color-danger">￥{{ amountStr }}</span>
      </div>
      <div class="margin-top layout-horizontal">
        <template v-if="schemeTrade.scheme">
          <el-image class="image" fit="cover" :src="$prepareImageUrl(schemeTrade.scheme.image)">
          </el-image>
          <div class="margin-left font-size-medium">
            <div>{{ schemeTrade.scheme.title }}</div>
            <div class="font-color-secondary">{{schemeTrade.scheme.message}}</div>
          </div>
        </template>
        <template v-else>
          <el-image class="image" fit="cover">
          </el-image>
          <div class="margin-left font-size-medium font-color-secondary">干预方案已下架或删除</div>
        </template>

      </div>
      <div class="margin-top font-color-warning">
        开始干预前，请确认已经收款。
      </div>
    </div>
    <div slot="footer">
      <ys-button type="secondary" lighting @click="handleCancelClick">取消</ys-button>
      <ys-button class="margin-left" lighting v-if="schemeTrade.scheme" @click="handleStartSchemeClick">开始干预</ys-button>
      <ys-button class="margin-left" lighting v-else @click="handleCloseSchemeClick">关闭交易</ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import {TimeUtils} from "@/assets/javascript/time-utils";
import ExactNumber from "@/assets/javascript/exact-number";
import TradeUtils from "@/assets/javascript/trade-utils";
import YsButton from "@/components/wedigets/YsButton";
import httpapi from "@/assets/javascript/httpapi";

function doStartScheme() {
  if(this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/scheme/trade/delivery',
    data: {
      id: this.schemeTrade.id,
    }
  }).then(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.success('干预方案已开始');
    this.hide();
    this.$emit('confirm');
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  });
}

function doCloseScheme() {
  if(this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/scheme/trade/close',
    data: {
      id: this.schemeTrade.id,
      reason: '干预方案已下架',
    }
  }).then(()=>{
    if(loadingCode != this.loadingCode) return;
    this.$message.success('已关闭交易');
    this.hide();
    this.$emit('confirm');
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "DeliveryTradeDialog",
  mixins: [httpapi],
  components: {YsButton, YsDialog},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    schemeTrade: Object,
  },
  data() {
    return {
      TradeUtils,
      TimeUtils,
      timeFormat: 'yyyy-MM-dd HH:mm:ss',

      isVisible: false,

      loadingCode: 1,
      busyLoadingCode: 0,

      amountStr: null,

    }
  },
  watch: {
    visible: {
      handler: function () {
        this.isVisible = this.visible;
      },
      immediate: true,
    },
    schemeTrade: {
      handler: function () {
        this.loadingCode++;
        this.amountStr = this.schemeTrade?.trade?.amount ? ExactNumber.stringify(this.schemeTrade.trade.amount) : "0.00";
      },
      immediate: true,
    }
  },
  methods: {
    hide:function (){
      this.isVisible = false;
      this.$emit('update:visible', false);
    },
    handleCancelClick: function () {
      this.hide();
    },
    handleStartSchemeClick: function () {
      if(this.schemeTrade?.scheme) {
        doStartScheme.bind(this)();
      }
    },
    handleCloseSchemeClick: function () {
      doCloseScheme.bind(this)();
    }
  }
}
</script>

<style scoped>
@import "../../../assets/css/style.css";

.image {
  width: 64px;
  height: 64px;
  border-radius: 8px;
  overflow: hidden;
}

</style>