<template>
  <div class="scheme-trade-list-panel">
    <ys-infinite-scroll-view
        class="scrollbar"
        @loadmore="loadSchemeTradeList">
      <scheme-trade-list-item v-for="schemeTrade in schemeTradeList" :key="schemeTrade.id" :schemeTrade="schemeTrade"
                              @click.native="handleSchemeTradeItemClick(schemeTrade)"></scheme-trade-list-item>
    </ys-infinite-scroll-view>
  </div>
</template>

<script>
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";
import SchemeTradeListItem from "@/pages/trade/basic/SchemeTradeListItem";

function doLoadSchemeTradeList() {
  if(this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  let lastSchemeTradeId = this.schemeTradeList.length > 0 ? this.schemeTradeList[this.schemeTradeList.length - 1].id : null;
  this.$reqGet({
    path: '/scheme/trade/refund_application/list/get/before/by_organization',
    data: {
      organizationId: this.organizationId,
      count: 20,
      id: lastSchemeTradeId,
    }
  }).then(res=>{
    if(loadingCode !== this.loadingCode) return;
    let schemeTradeList = this.schemeTradeList;
    this.$appendAfter(schemeTradeList, res.data);
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "RefundApplicationListPanel",
  mixins: [httpapi],
  components: {SchemeTradeListItem, YsInfiniteScrollView},
  props: {
    organizationId: Number,
  },
  data() {
    return {
      loadingCode: 1,
      busyLoadingCode: 0,

      schemeTradeList: [],
    }
  },
  watch: {
    organizationId: {
      handler: function () {
        this.loadingCode ++;
        this.schemeTradeList = [];
        if(this.organizationId) {
          this.loadSchemeTradeList();
        }
      },
      immediate: true,
    }
  },
  methods: {
    updateSchemeTrade: function (schemeTrade) {
      if(schemeTrade.organization.id === this.organizationId) {
        this.$appendBefore(this.schemeTradeList, [schemeTrade]);
      }
    },
    loadSchemeTradeList: function () {
      doLoadSchemeTradeList.bind(this)();
    },

    handleSchemeTradeItemClick(schemeTrade) {
      this.$emit('itemclick', {item: schemeTrade})
    }
  }
}
</script>

<style scoped>

</style>