<template>
  <div class="user-avatar"
    :class="[
        'avatar-size__' + size,
    ]">
    <el-image
        class="image"
        fit="cover"
        :src="$prepareImageUrl(user.avatar)"
        v-if="user && user.avatar"
    ></el-image>
    <i class="fa fa-user" v-else></i>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";

export default {
  name: "UserAvatar",
  mixins: [httpapi],
  props: {
    user: Object,
    size: {
      type: String,
      default: 'normal'
    }
  }
}
</script>

<style scoped>

.user-avatar {
  border-radius: 50%;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #f091a6;
}

.user-avatar {
  box-shadow: 0 0 4px #f091a6;
}

.user-avatar .image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.user-avatar.avatar-size__normal {
  width: 28px;
  height: 28px;
  font-size: 24px;
}

.user-avatar.avatar-size__extra-small {
  width: 18px;
  height: 18px;
  font-size: 14px;
}

.user-avatar.avatar-size__small {
  width: 24px;
  height: 24px;
  font-size: 18px;
}

.user-avatar.avatar-size__large {
  width: 36px;
  height: 36px;
  font-size: 32px;
}

.user-avatar.avatar-size__extra-large {
  width: 56px;
  height: 56px;
  font-size: 48px;
}





</style>