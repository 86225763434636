<template>
  <ys-dialog :visible.sync="isVisible" @update:visible="visible=>$emit('update:visible', visible)" title="退款">
    <div class="padding padding-large font-size-medium">
      <div>
        <span>订单号：</span>
        <span>{{ schemeTrade.trade.tradeNo }}</span>
      </div>
      <div>
        <span>创建时间：</span>
        <span>{{ TimeUtils.format(timeFormat, schemeTrade.trade.createTime) }}</span>
      </div>
      <div>
        <span>状态：</span>
        <span>{{ TradeUtils.formatTradeStatus(schemeTrade.trade) }}</span>
      </div>
      <div>
        <span>订单金额：</span>
        <span class="font-weight-bold font-size-extra-large font-color-danger">￥{{ amountStr }}</span>
      </div>
      <div>
        <span>申请退款原因：</span>
        <span v-if="schemeTrade.trade.refundApplyReason">{{schemeTrade.trade.refundApplyReason}}</span>
      </div>
      <div class="margin-top">
        <ys-textarea placeholder="拒绝退款原因" :maxlength="80" v-model="reasonInput"></ys-textarea>
      </div>
    </div>
    <div slot="footer">
      <ys-button type="secondary" lighting @click="handleCancelClick">取消</ys-button>
      <ys-button class="margin-left" lighting @click="handleConfirmClick">拒绝退款</ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import YsTextarea from "@/components/wedigets/YsTextarea";
import httpapi from "@/assets/javascript/httpapi";
import {TimeUtils} from "@/assets/javascript/time-utils";
import TradeUtils from "@/assets/javascript/trade-utils";
import YsDialog from "@/components/wedigets/YsDialog";
import ExactNumber from "@/assets/javascript/exact-number";
import YsButton from "@/components/wedigets/YsButton";
import TextUtils from "@/assets/javascript/text-utils";

function doRejectRefund() {
  if(this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/scheme/trade/refund/reject',
    data: {
      id: this.schemeTrade.id,
      reason: this.reasonInput,
    }
  }).then(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.success('已拒绝用户的退款申请');
    this.hide();
    this.$emit('confirm');
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "RejectRefundDialog",
  mixins: [httpapi],
  components: {YsButton, YsDialog, YsTextarea},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    schemeTrade: Object,
  },
  data() {
    return {
      TimeUtils,
      TradeUtils,
      timeFormat: 'yyyy-MM-dd HH:mm:ss',

      isVisible: false,

      amountStr: null,
      reasonInput: null,

      loadingCode: 1,
      busyLoadingCode: 0,
    }
  },
  watch: {
    visible: {
      handler: function (visible) {
        this.isVisible = visible;
        this.reasonInput = null;
      },
      immediate: true,
    },
    schemeTrade: {
      handler: function () {
        this.loadingCode ++;
        this.amountStr = this.schemeTrade.trade.amount ? ExactNumber.stringify(this.schemeTrade.trade.amount) : "0.00";
      },
      immediate: true,
    }
  },
  methods: {
    hide: function () {
      this.isVisible = false;
      this.$emit('update:visible', false);
    },
    handleCancelClick: function () {
      this.hide();
    },
    handleConfirmClick: function () {
      if(TextUtils.isBlank(this.reasonInput)) {
        this.$message.warning('请输入拒绝退款的原因');
        return;
      }
      this.rejectRefund();
    },
    rejectRefund: function () {
      doRejectRefund.bind(this)();
    }
  }
}
</script>

<style scoped>

</style>