<template>
  <div class="scheme-trade-list-item padding-bottom-large font-size-medium">
    <div class="padding-horizontal-large padding-top-large padding-bottom layout-horizontal layout-middle">
      <div class="font-weight-bold">订单</div>
      <div class="layout-flexible"></div>
      <div>
        <span>状态：</span>
        <span>{{ statusStr }}</span>
      </div>
    </div>
    <div class="padding-horizontal-large">
      <div class="divider divider-horizontal"></div>
    </div>
    <div class="padding-horizontal-large layout-horizontal layout-middle padding-vertical">
      <span>支付者：</span>
      <user-avatar :user="innerSchemeTrade.trade.payer" size="small"></user-avatar>
      <span class="margin-left">{{ innerSchemeTrade.trade.payer.nickName }}</span>
    </div>
    <div class="padding-horizontal-large layout-horizontal layout-middle padding-top-small">
      <span>用户：</span>
      <span>{{innerSchemeTrade.user.name}}</span>
    </div>
    <div class="padding-horizontal-large layout-horizontal layout-middle padding-top-small">
      <span>联系电话：</span>
      <span v-if="innerSchemeTrade.user.phone" class="font-color-primary font-weight-bold"><i class="fas fa-phone"></i> {{innerSchemeTrade.user.phone.phone}}</span>
    </div>
    <div class="padding-horizontal-large padding-top-small">
      <span>订单编号：</span>
      <span>{{ innerSchemeTrade.trade.tradeNo }}</span>
    </div>
    <div class="padding-horizontal-large padding-top-small">
      <span>创建时间：</span>
      <span>{{ TimeUtils.format(timeFormat, innerSchemeTrade.trade.createTime) }}</span>
    </div>
    <div class="padding-horizontal-large padding-top-small" v-if="innerSchemeTrade.trade.payTime">
      <span>付款时间：</span>
      <span>{{ TimeUtils.format(timeFormat, innerSchemeTrade.trade.payTime) }}</span>
    </div>
    <div class="padding-horizontal-large padding-top-small">
      <span>支付者备注：</span>
      <span v-if="innerSchemeTrade.trade.payerRemark">{{ innerSchemeTrade.trade.payerRemark }}</span>
    </div>
    <div class="padding-horizontal-large padding-top-small">
      <span>订单备注：</span>
      <span></span>
    </div>
    <template v-if="innerSchemeTrade.trade.status === TradeStatus.REFUND_APPLIED">
      <div class="padding-horizontal-large padding-vertical">
        <div class="divider divider-horizontal"></div>
      </div>
      <div class="padding-horizontal-large">
        <span>申请退款：</span>
        <span>{{ TimeUtils.format(timeFormat, innerSchemeTrade.trade.refundApplyTime) }}</span>
      </div>
      <div class="padding-horizontal-large padding-top-small">
        <span>退款原因：</span>
        <span v-if="innerSchemeTrade.trade.refundApplyReason">{{ innerSchemeTrade.trade.refundApplyReason }}</span>
      </div>
    </template>
    <template v-if="innerSchemeTrade.trade.refundReject">
      <div class="padding-horizontal-large padding-vertical">
        <div class="divider divider-horizontal"></div>
      </div>
      <div class="padding-horizontal-large">拒绝退款</div>
      <div class="padding-horizontal-large padding-top-small">
        <span>拒绝退款原因：</span>
        <span v-if="innerSchemeTrade.trade.refundRejectReason">{{innerSchemeTrade.trade.refundRejectReason}}</span>
      </div>

    </template>
    <div class="padding-horizontal-large padding-vertical font-size-small font-color-secondary">
      <span>{{ innerSchemeTrade.trade.description }}</span>
    </div>
    <div class="padding-horizontal-large padding-vertical">
      <div class="divider divider-horizontal"></div>
    </div>
    <div class="font-align-right padding-horizontal-large">
      <div>
        <span
            v-if="innerSchemeTrade.trade.status === TradeStatus.CREATED || innerSchemeTrade.trade.status === TradeStatus.TRADE_CLOSED">金额：</span>
        <span v-else>实际支付：</span>
        <span style="color: #ff5500; font-weight: bold" class="font-size-extra-large">￥{{ amountStr }}</span>
      </div>
    </div>
    <div class="layout-horizontal layout-middle layout-right padding-horizontal-large padding-vertical">
      <div class="btn-danger btn-plain" @click.stop="handleCloseTradeClick"
           v-if="innerSchemeTrade.trade.status !== TradeStatus.TRADE_CLOSED">关闭交易
      </div>
      <div class="btn-danger btn-plain margin-left" v-if="innerSchemeTrade.trade.status === TradeStatus.REFUND_APPLIED"
          @click.stop="handleRejectRefundClick">拒绝退款</div>
      <div class="btn-danger margin-left"
           v-if="!innerSchemeTrade.trade.delivered && innerSchemeTrade.trade.status !== TradeStatus.TRADE_CLOSED"
           @click="handleDeliveryTradeClick">开始干预
      </div>
      <div class="btn-danger margin-left" v-else-if="isRefundButtonShow"
          @click="handleRefundClick">退款</div>
    </div>
    <template v-if="innerSchemeTrade.refundList.length > 0">
      <div class="font-color-secondary padding-vertical padding-horizontal-large">退款记录：</div>
      <div class="layout-horizontal layout-middle font-size-small font-color-secondary padding-horizontal-large">
        <span style="width: 30%;">时间</span>
        <span style="width: 30%; text-align: center;" class="margin-left">状态</span>
        <span style="width: 40%; text-align: right;" class="margin-left">金额</span>
      </div>
      <div class="layout-horizontal layout-middle padding padding-large" v-for="refund in innerSchemeTrade.refundList"
           :key="refund.id">
        <span style="width: 30%;">{{ TimeUtils.format('yyyy-MM-dd\nHH:mm:ss', refund.createTime) }}</span>
        <span style="width: 30%;"
              class="margin-left font-align-center">{{ TradeUtils.formatRefundStatus(refund) }}</span>
        <span style="width: 40%;"
              class="margin-left font-weight-bold font-color-danger font-align-right"
              v-if="refund.status === TradeStatus.REFUND_PROCESSING || refund.status === TradeStatus.REFUND_SUCCESS">-￥{{
            ExactNumber.stringify(refund.amount)
          }}</span>
        <span v-else style="width: 40%; text-decoration: line-through"
              class="margin-left font-weight-bold font-color-secondary font-align-right">- ￥{{ExactNumber.stringify(refund.amount)}}</span>
      </div>
    </template>
    <CloseTradeDialog :visible.sync="closeTradeDialogVisible" :scheme-trade="innerSchemeTrade"
                      @confirm="handleCloseTradeConfirm"></CloseTradeDialog>
    <DeliveryTradeDialog :visible.sync="deliveryTradeDialogVisible" :scheme-trade="innerSchemeTrade"
                         @confirm="handleDeliveryTradeConfirm">
    </DeliveryTradeDialog>
    <RefundDialog :visible.sync="refundDialogVisible" :scheme-trade="schemeTrade"
                      @confirm="handleRefundConfirm"></RefundDialog>
    <RejectRefundDialog :visible.sync="rejectRefundDialogVisible" :scheme-trade="schemeTrade"
                      @confirm="handleRejectRefundConfirm"></RejectRefundDialog>

  </div>
</template>

<script>
import UserAvatar from "@/components/user/UserAvatar";
import {TimeUtils} from "@/assets/javascript/time-utils";
import TradeUtils, {PaymentType, TradeStatus} from "@/assets/javascript/trade-utils";
import ExactNumber from "@/assets/javascript/exact-number";
import CloseTradeDialog from "@/pages/trade/dialog/CloseTradeDialog";
import httpapi from "@/assets/javascript/httpapi";
import DeliveryTradeDialog from "@/pages/trade/dialog/DeliveryTradeDialog";
import RefundDialog from "@/pages/trade/dialog/RefundDialog";
import RejectRefundDialog from "@/pages/trade/dialog/RejectRefundDialog";

function doLoadSchemeTrade() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/scheme/trade/get',
    data: {
      id: this.innerSchemeTrade.id,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.innerSchemeTrade = res.data;
    this.$emit('update:schemeTrade', this.innerSchemeTrade);
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "SchemeTradePanel",
  mixins: [httpapi],
  components: {RejectRefundDialog, RefundDialog, DeliveryTradeDialog, CloseTradeDialog, UserAvatar},
  props: {
    schemeTrade: Object,
  },
  data() {
    return {
      /**
       * 常量
       */
      TimeUtils,
      TradeUtils,
      ExactNumber,
      timeFormat: 'yyyy-MM-dd HH:mm:ss',
      TradeStatus,
      PaymentType,

      innerSchemeTrade: null,
      /**
       * 格式化字符串
       */
      statusStr: null,
      amountStr: null,


      /**
       * 页面状态
       */
      closeTradeDialogVisible: false,
      deliveryTradeDialogVisible: false,
      refundDialogVisible: false,
      rejectRefundDialogVisible: false,
      isRefundButtonShow: false,

      /**
       * 数据加载状态
       */
      loadingCode: 1,
      busyLoadingCode: 0,

    }
  },
  watch: {
    schemeTrade: {
      handler: function (schemeTrade) {
        this.loadingCode++;
        this.innerSchemeTrade = schemeTrade;
      },
      immediate: true,
    },
    innerSchemeTrade: {
      handler: function (schemeTrade) {
        let statusStr = null;
        let amountStr = null;
        let isRefundButtonShow = false;
        statusStr = TradeUtils.formatTradeStatus(schemeTrade.trade);
        amountStr = schemeTrade.trade.amount ? ExactNumber.stringify(schemeTrade.trade.amount) : "0.00";
        if (schemeTrade.trade.paymentType != PaymentType.FREE && ExactNumber.compare(schemeTrade.trade.amount, schemeTrade.trade.refundAmount ? schemeTrade.trade.refundAmount : ExactNumber.of(0)) > 0) {
          switch (schemeTrade.trade.status) {
            case TradeStatus.PAIED_SUCCESS:
            case TradeStatus.REFUND_APPLIED:
            case TradeStatus.REFUND_SUCCESS:
            case TradeStatus.REFUND_CLOSED:
            case TradeStatus.REFUND_ABNORMAL:
              isRefundButtonShow = true;
              break;
          }
        }
        this.statusStr = statusStr;
        this.amountStr = amountStr;
        this.isRefundButtonShow = isRefundButtonShow;
      },
      immediate: true,
    }
  },
  methods: {
    handleCloseTradeClick: function () {
      this.closeTradeDialogVisible = true;
    },
    handleCloseTradeConfirm: function () {
      doLoadSchemeTrade.bind(this)();
    },
    handleDeliveryTradeClick: function () {
      this.deliveryTradeDialogVisible = true;
    },
    handleDeliveryTradeConfirm: function () {
      doLoadSchemeTrade.bind(this)();
    },
    handleRefundClick: function () {
      this.refundDialogVisible = true;
    },
    handleRefundConfirm: function () {
      doLoadSchemeTrade.bind(this)();
    },
    handleRejectRefundClick: function () {
      this.rejectRefundDialogVisible = true;
    },
    handleRejectRefundConfirm: function () {
      doLoadSchemeTrade.bind(this)();
    }
  }
}
</script>

<style scoped>

.scheme-trade-list-item {
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
  min-width: 400px;
  max-width: 400px;
  box-shadow: 0 0 4px #dadada;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.btn-danger {
  background-color: #ff5500;
  color: white;
  padding: 8px 8px;
  border-radius: 100px;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  min-width: 88px;
  cursor: pointer;
  user-select: none;
}

.btn-danger.btn-plain {
  border: 1px solid #ff5500;
  color: #ff5500;
  background-color: white;
}

.btn-danger:hover {
  opacity: .8;
}
</style>