<template>
  <div class="scheme-trade-list-item list-item layout-horizontal layout-middle">
    <div style="width: 20%; white-space: normal; word-break: break-all;">
      {{ schemeTrade.trade.tradeNo }}
    </div>
    <div style="width: 10%;" class="margin-left-large">
      <span>{{ TimeUtils.format(timeFormat, schemeTrade.trade.createTime) }}</span>
    </div>
    <div class="layout-horizontal layout-middle margin-left" style="width: 10%;">
      <user-avatar :user="schemeTrade.trade.payer" size="small"></user-avatar>
      <span class="margin-left font-size-medium">{{ schemeTrade.trade.payer.nickName }}</span>
    </div>
    <div class="layout-horizontal layout-middle margin-left font-color-secondary"
         style="width: 35%; text-overflow: ellipsis; max-height: 38px;">
      <span>{{ schemeTrade.trade.description }}</span>
    </div>
    <div class="layout-horizontal layout-middle margin-left" style="width: 10%;">
      <span class="status status--is-warning" v-if="isWarning">{{ statusStr }}</span>
      <span class="status status--is-success" v-else-if="isSuccess">{{ statusStr }}</span>
      <span class="status" v-else>{{ statusStr }}</span>
    </div>
    <div class="layout-horizontal layout-middle margin-left font-align-right layout-horizontal layout-right"
         style="width: 15%;">
      <span class="font-weight-bold font-color-danger font-size-large font-align-right">￥{{ amountStr }}</span>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/user/UserAvatar";
import {TimeUtils} from "@/assets/javascript/time-utils";
import ExactNumber from "@/assets/javascript/exact-number";
import TradeUtils, {TradeStatus} from "@/assets/javascript/trade-utils";

export default {
  name: "SchemeTradeListItem",
  components: {UserAvatar},
  props: {
    schemeTrade: Object,
  },
  data() {
    return {
      TimeUtils,
      timeFormat: 'yyyy-MM-dd\nHH:mm:ss',

      statusStr: null,
      amountStr: null,

      isSuccess: false,
      isWarning: false,

    }
  },
  watch: {
    schemeTrade: {
      handler: function (schemeTrade) {
        let statusStr = null;
        let amountStr = null;
        let isSuccess = false;
        let isWarning = false;
        statusStr = TradeUtils.formatTradeStatus(schemeTrade.trade);
        amountStr = schemeTrade.trade.amount ? ExactNumber.stringify(schemeTrade.trade.amount) : "0.00";
        switch (schemeTrade.trade.status) {
          case TradeStatus.PAIED_SUCCESS:
            if (schemeTrade.trade.delivered) {
              isSuccess = true;
            } else {
              isWarning = true;
            }
            break;
          case TradeStatus.REFUND_APPLIED:
          case TradeStatus.REFUND_ABNORMAL:
            isWarning = true;
            break;
        }
        this.statusStr = statusStr;
        this.amountStr = amountStr;
        this.isSuccess = isSuccess;
        this.isWarning = isWarning;
      },
      immediate: true,
    }
  }
}
</script>

<style scoped>

.list-item {
  width: unset;
  max-width: 60vw;
}

.status {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #f1f1f1;
}

.status.status--is-warning {
  background-color: #ff5500;
  color: white;
}

.status.status--is-success {
  background-color: #5daf34;
  color: white;
}


</style>